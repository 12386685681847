import { Modal, Button, Progress } from "@mantine/core";
import {
	createProductWithQueue,
	updateProductWithQueue,
	getProductsChannels,
	getAllProductsChannelsTypesLinks,
} from "../redux/slices/product";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formateMetafield, getMetafields } from "../redux/slices/metafields";
import { useUser } from "src/context/UserContext";
import type { NewTagProperties } from "src/@types/props";

export default function ConfirmModal({
	opened,
	actions,
	rowData,
	setAwaitingProduct,
	handleClosePreview,
	ignoreUpdate,
	ignoreCreate,
}: NewTagProperties) {
	const { user } = useUser();

	const { t } = useTranslation();

	const [importSatus, setImportStatus] = useState<boolean>(false);

	const handleImportClick = async () => {
		const { data } = await getMetafields();
		const products = formateMetafield(rowData, data);
		const toCreate = ignoreCreate
			? null
			: products?.filter(
					(product: { Action: string }) =>
						product.Action === t("confirmModal.action.create"),
				);
		const toUpdate = ignoreUpdate
			? null
			: products?.filter(
					(product: { Action: string }) =>
						product.Action === t("confirmModal.action.update"),
				);
		try {
			setImportStatus(true);
			if (toCreate?.length && user) {
				const productChannels = await (async () => {
					const links: {
						id: string;
						publicationExternalId: string;
						publicationTypeId: string;
					}[] = await getAllProductsChannelsTypesLinks();

					const names: {
						publicationId: string;
						publicationName: string;
					}[] = await getProductsChannels();

					const namesMap = names.reduce(
						(acc, { publicationId, publicationName }) => {
							acc[publicationId] = publicationName;
							return acc;
						},
						{} as { [key: string]: string },
					);

					return links.reduce(
						(acc, { publicationExternalId, publicationTypeId }) => {
							const name = namesMap[publicationExternalId] || "Unknown";
							if (name === "Online Store" || name === "Point of Sale") {
								acc.push(publicationTypeId);
							}
							return acc;
						},
						[] as string[],
					);
				})();
				await createProductWithQueue(toCreate, user.id, productChannels);
			}

			if (toUpdate?.length && user) {
				await updateProductWithQueue(toUpdate, user.id);
			}

			notifications.show({
				title: "Success",
				message: `${t("confirmModal.notification.success")}`,
				color: "blue",
			});
			setAwaitingProduct(true);
			handleClosePreview();
			actions.close();
		} catch (error) {
			notifications.show({
				title: "Error",
				message: `${t("confirmModal.notification.error")},${error.message}`,
				color: "red",
			});
		} finally {
			setImportStatus(false);
		}
	};

	return (
		<Modal
			zIndex={300}
			opened={opened}
			onClose={actions.close}
			title={t("confirmModal.title")}
			centered
		>
			<Button
				disabled={importSatus}
				fullWidth
				onClick={() => handleImportClick()}
			>
				{t("confirmModal.import")}
			</Button>
			{importSatus && (
				<Progress style={{ marginTop: 10 }} value={100} animated />
			)}
		</Modal>
	);
}
