import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "../enums/Language";
import { Select } from "@mantine/core";
import type {ComboboxItem} from "@mantine/core";

const Lang = () => {
	const { i18n } = useTranslation();
	const [lang, setLang] = useState<Language>(i18n.language as Language);

	const changeLanguage = (value: string | null, option: ComboboxItem) => {
		const language = value;

		switch (language) {
			case Language.EN:
				setLang(Language.EN);
				i18n.changeLanguage(Language.EN);
				break;
			case Language.FR:
				setLang(Language.FR);
				i18n.changeLanguage(Language.FR);
				break;
		}
	};

	return (
		<div>
			<Select
				value={lang}
				name="language"
				onChange={changeLanguage}
				style={{ width: "100px", height: "36px", padding: "0px 10px" }}
				data={[
					{ value: Language.FR, label: "FR" },
					{ value: Language.EN, label: "EN" },
				]}
				checkIconPosition="right"
			/>
		</div>
	);
};

export default Lang;
