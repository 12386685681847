import axios from 'axios';
// config
import { BOT_PROTECTION_KEY } from '../config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
    headers: { 'x-bot-protection-key': BOT_PROTECTION_KEY },
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error.response?.data || 'Something went wrong')
);

export default axiosInstance;
