import { WORKERS } from "../../config-global";
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

export type Metafield = {
	key: string;
	displayname: string;
	external_id: string;
	metadata_external_id: string;
	value: string;
	type: string;
};

type RowData = {
	[key: string]: string;
};
const queryParameters = new URLSearchParams(window.location.search);
export async function getMetafields(): Promise<{
	ok: boolean;
	data: Metafield[];
}> {
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	const response = await axios.get(
		`${WORKERS.products}/${siteId}/metafields?`,
		{
			headers: {
				Authorization: bearerToken,
			},
		},
	);

	const metafields: Metafield[] = response.data.data;
	return { ok: true, data: metafields };
}

export const formateMetafield = (
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	rowData: any[],
	metafieldsList: Metafield[],
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
): any => {

	const trueValue = ["true", "1", "yes", "oui", "vrai"];

	return rowData.map((data) => {
		const metadata: RowData[] = [];
		for (const key in data) {
			if (key.startsWith("meta_")) {
				const newKey = key.slice(5);
				metadata.push({ key: newKey, value: data[key] || "" });
			}
		}

		const formattedValue: {
			key: string;
			value: string | string[] | boolean | number;
		}[] = [];
		for (const field of metadata) {
			const foundMetafield = metafieldsList.find(
				(metafield) => metafield.key === field.key,
			);
			const typeOfField = foundMetafield ? foundMetafield.type : "";
			switch (typeOfField) {
				case "number_decimal": {
					const decimalValue = Number.parseFloat(field.value);
					if (!Number.isNaN(decimalValue)) {
						const value = decimalValue.toLocaleString(undefined, {
							minimumFractionDigits: 0,
							maximumFractionDigits: 9,
						});
						formattedValue.push({ key: field.key, value: value });
					}
					break;
				}
				case "Integer": {
					const integerValue = BigInt(field.value);
					if (!Number.isNaN(integerValue)) {
						if (
							integerValue >= BigInt("-4611686018427387904") &&
							integerValue <= BigInt("4611686018427387903")
						) {
							formattedValue.push({
								key: field.key,
								value: integerValue.toString(),
							});
						} else {
							throw new Error("Integer value is out of range");
						}
					}
					break;
				}
				case "list.single_line_text_field": {
					if (field.value.includes(",")) {
						const values = field.value.split(",");
						formattedValue.push(
							...values.map((value) => ({ key: field.key, value })),
						);
					} else {
						formattedValue.push({ key: field.key, value: [field.value] });
					}
					break;
				}
				case "list.url": {
					if (field.value.includes(",")) {
						const urls = field.value.split(",");
						for (const url of urls) {
							if (!url.startsWith("https://")) {
								throw new Error("Url should start with https://");
							}
							formattedValue.push({ key: field.key, value: url });
						}
					} else {
						if (!field.value.startsWith("https://")) {
							throw new Error("Url should start with https://");
						}
						formattedValue.push({ key: field.key, value: [field.value] });
					}
					break;
				}
				case "url": {
					if (!field.value.startsWith("https://")) {
						throw new Error("Url should start with https://");
					}
					formattedValue.push({ key: field.key, value: [field.value] });
					break;
				}
				default: {
					if (
						field.key === "contenu_digital" ||
						field.key === "contenu_explicite"
					) {
						const value = trueValue.includes(field.value.toLowerCase());
						formattedValue.push({ key: field.key, value: value });
					} else {
						formattedValue.push({ key: field.key, value: field.value });
					}
					break;
				}
			}
		}
		const meta_data = formattedValue.map((data) => {
			data.key = `meta_${data.key}`;
			return data;
		});
		for (const key in data) {
			if (key.startsWith("meta_")) {
				const foundMeta = meta_data.find((meta) => meta.key === key);
				if (foundMeta) data[key] = foundMeta.value;

			}
		}

		return {
			...data,
			metadata: formattedValue,
		};
	});
};
