import axios from "../utils/axios";
import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	type ReactNode,
} from "react";
import { WORKERS } from "src/config-global";

interface User {
	id: string;
	email: string;
	displayName: string | null;
	firstName: string;
	lastName: string;
	avatarUrl: string;
	role: string;
	permissions: string[];
}

interface UserContextType {
	user: User | null;
	isAdmin: boolean;
	hasPermission: (permission: string) => boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
	const [user, setUser] = useState<User | null>(null);

	useEffect(() => {
		// Remplacez par votre logique d'appel API pour récupérer les infos utilisateur

		const fetchUser = async () => {
			try {
				const queryParameters = new URLSearchParams(window.location.search);

				const accessToken = queryParameters.get("act");

				if (accessToken) {
					const response = await axios.get(`${WORKERS.login}/me`, {
						headers: {
							Authorization: accessToken,
						},
					});

					const data = response.data.data;

					// console.log("data me", response.data.data);

					if (response.data.ok) {
						setUser(data);
					}
				}
			} catch (error) {
				console.error("Error fetch user", error);
			}
		};

		fetchUser();
	}, []);

	const isAdmin = user?.role === "ADMIN";

	const hasPermission = (permission: string) => {
		return user?.permissions.includes(permission) || false;
	};

	return (
		<UserContext.Provider value={{ user, isAdmin, hasPermission }}>
			{children}
		</UserContext.Provider>
	);
};

export const useUser = () => {
	const context = useContext(UserContext);
	if (context === undefined) {
		throw new Error("useUser must be used within a UserProvider");
	}
	return context;
};
