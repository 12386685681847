import type {
	IProduct,
	Product,
	IPagination,
	resultHeavy,
} from "../../@types/product";
import { WORKERS } from "../../config-global";
// utils
import axios from "../../utils/axios";
type RowData = {
	[key: string]: string;
};
const queryParameters = new URLSearchParams(window.location.search);

const generateHandle = (title: string, barcode: string): string => {
	// biome-ignore lint/suspicious/noMisleadingCharacterClass: <explanation>
	const cleanedTitle = title.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	const cleanedBarcode = barcode
		.normalize("NFD")
		// biome-ignore lint/suspicious/noMisleadingCharacterClass: <explanation>
		.replace(/[\u0300-\u036f]/g, "");

	const lowercaseTitle = cleanedTitle.toLowerCase().replace(/[^a-z0-9\s]/g, "");
	const lowercaseBarcode = cleanedBarcode
		.toLowerCase()
		.replace(/[^a-z0-9\s]/g, "");

	const titleWithHyphens = lowercaseTitle.replace(/\s+/g, "-");
	const barcodeWithHyphens = lowercaseBarcode.replace(/\s+/g, "-");

	const handle = `${titleWithHyphens}-${barcodeWithHyphens}`;

	return handle;
};

const dvdTypes = ["DVD", "4K Ultra HD", "HD DVD", "Blu-ray 3D", "Blu-ray"];
const bookTypes = [
	"Comics",
	"Grand format",
	"MANGA",
	"Beaux-livres",
	"Jeux et livre coquins",
	"Poche",
];
const merchTypes = ["Merchandising"];
const musicTypes = ["CD de musique"];

const getDefaultImage = (productType: string): string => {
	if (dvdTypes.includes(productType)) {
		return "https://cdn.shopify.com/s/files/1/0793/6776/6332/files/MDP_default_img_dvd.jpg?v=1722344708";
	}
	if (bookTypes.includes(productType)) {
		return "https://cdn.shopify.com/s/files/1/0793/6776/6332/files/MDP_default_img_book.jpg?v=1722344708";
	}
	if (merchTypes.includes(productType)) {
		return "https://cdn.shopify.com/s/files/1/0793/6776/6332/files/MDP_default_img_merch.jpg?v=1722344708";
	}
	if (musicTypes.includes(productType)) {
		return "https://cdn.shopify.com/s/files/1/0793/6776/6332/files/MDP_default_img_music.jpg?v=1722344708";
	}
	return "https://cdn.shopify.com/s/files/1/0793/6776/6332/files/MDP_default_img.jpg?v=1722344708";
};

export function convertRowDataToProduct(
	rowData: RowData[],
	isUpdate = false,
	productChannels?: string[],
): Product[] {
	const result = rowData.map((data) => {
		const trueValue = ["true", "1", 1, "yes", "oui", "vrai", "continue"];
		const falseValue = ["false", "0", 0, "no", "non", "faux", "deny"];

		const metadata: RowData[] = [];
		for (const key in data) {
			if ((key.startsWith("meta_") && isUpdate) || (key.startsWith("meta_") && data[key] !== "")) {
				const newKey = key.slice(5);
				metadata.push({ key: newKey, value: data[key] || "" });
			}
		}

		const getSKUPrefix = (productType: string) => {
			if (dvdTypes.includes(productType)) {
				return "VID";
			}
			if (bookTypes.includes(productType)) {
				return "PAP";
			}
			if (merchTypes.includes(productType)) {
				return "MER";
			}
			if (musicTypes.includes(productType)) {
				return "MUS";
			}
			return "MDP";
		};
		const product: Product = {
			body_html: data.Description || data["Body (HTML)"] || "",
			title: data.Title || "not provided",
			code: data.code || "",
			metadata: metadata,
			product_type: data["Product Type"] || "",
			// publicationType: data["publication type"] || "", //TODO: add publicationType management set intensive by default
			sku: data.SKU || "",
			status: "active", //data.Status ||
			variants: [
				{
					position: data["variant position"] || 0,
					barcode: data["Variant Barcode"].toString(),
					grams: data.grams || "0",
					// inventories: data["Variant Inventories"] || [],
					inventory_management: data.variant_inventory_management || "shopify",
					inventory_policy: trueValue.includes(data["Variant Inventory Policy"])
						? "continue"
						: "deny",
					inventory_quantity: 0, // data["Variant Inventory Quantity"] || 0,
					option1: data["variant option1"] || "",
					option2: data["variant option2"] || "",
					option3: data["variant option3"] || "",
					price: data["Variant Price"].toString(),
					taxable: trueValue.includes(data["variant taxable"]) || true,
					sku:
						data["Variant SKU"] ||
						data.variant_sku ||
						`${getSKUPrefix(data["Product Type"])}${data["Variant Barcode"].toString()}`,
					title: data.Title,
					weight: data["Variant Weight"] || "0",
					weight_unit: data["Weight Unit"] || "g",
					requires_shipping:
						!falseValue.includes(data["Variant Requires Shipping"]) || true,
					inventories: [],
					fulfillment_service: data["variant fulfillment service"] || "manual",
				},
			],
			template_suffix: data["Template Suffix"] || "",
			tags: data.Tags || "",
			options: [
				{
					name: "Title",
					values: ["Default Title"],
				},
			],
			handle: generateHandle(data.Title, data["Variant Barcode"].toString()),
			vendor: data.Vendor,
		};
		if (
			(data["Images Src"] &&
				!data["Images Src"].endsWith("no_image.png") &&
				!data["Images Src"].endsWith("no_image_dvd.png")) ||
			(data["images src"] &&
				!data["images src"].endsWith("no_image.png") &&
				!data["images src"].endsWith("no_image_dvd.png"))
		) {
			product.images =
				data["images src"]?.includes(",") || data["Images Src"]?.includes(",")
					? data["Images Src"]?.split(",").map((src) => ({ src })) ||
						data["images src"]?.split(",").map((src) => ({ src }))
					: [{ src: data["images src"] || data["Images Src"] }];
		} else {
			product.images = [{ src: getDefaultImage(product.product_type) }];
		}
		if (metadata.length > 0) {
			for (const data of metadata) {
				product[`meta_${data.key}` as keyof Product] = data.value;
			}
		}
		product.productSync = data.productSync;

		if (isUpdate) {
			// if (data.publications) {
			// 	product.publications = data.publications.split(", ");
			// }
			product.handle = data.handle;
			product.external_id = data["external id"] || data.external_id;
			product.id = data.id;
			product.options = [
				{
					id: data["option id"] || data.option_id,
					external_id: data["option external id"] || data.option_external_id,
					name: data["option name"] || data.option_name,
					position:
						Number(data["option position"]) || Number(data.option_position),
					values: [data["option values"]] || [data.option_values],
				},
			];
			product.variants[0].fulfillment_service = data[""];
			product.variants[0].id = data.variant_id;
			product.variants[0].image_id = data.variant_image_id;
			product.variants[0].external_id = data.variant_external_id || "";
			product.variants[0].inventory_item_id =
				Number(data.variant_inventory_item_id) || 0;
			product.images = data["Images Src"]
				? data["Images Src"].split(",").map((src) => ({ src }))
				: data["images src"]
					? data["images src"].split(",").map((src) => ({ src }))
					: data.images_id?.split(",").map((id) => ({ id }));
		} else {
			product.publications = productChannels;
		}
		return product;
	});

	return result;
}

export function convertIProductToRowData(product: IProduct): RowData {
	try {
		let rowData: RowData = {
			handle: product.handle,
			Title: product.title,
			"Variant Price": product.variants?.[0]?.price?.toString() ?? "",
		};

		//TODO: add stock management

		// options
		if (product.options?.length > 0) {
			// for (let i = 0; i < product.options.length; i++) {
			rowData["option position"] =
				product.options[0].position?.toString() || "";
			rowData["option name"] = product.options[0].name;
			rowData["option values"] = product.options[0]?.values?.join(", ");
			// }
		}

		rowData = {
			...rowData,
			...{
				// "Variant Title": product.variants?.[0]?.title,
				"Product Type": product.product_type,
				"variant option1": product.variants?.[0]?.option1,
				"Variant SKU": product.variants?.[0]?.sku,
				"Variant Barcode": product.variants?.[0]?.barcode,
			},
		};

		// images
		if (product.images?.length > 0) {
			for (const image of product.images) {
				rowData.images_id = `${rowData.images_id ?? ""}${rowData.images_id ? "," : ""}${image.external_id}`;
				rowData["images src"] =
					`${rowData["images src"] ?? ""}${rowData["images src"] ? "," : ""}${image.src}`;
			}
		}

		rowData = {
			...rowData,
			...{
				Tags: product.tags,
				"Variant Requires Shipping":
					product.variants?.[0]?.requires_shipping?.toString() ?? "true",
				"Variant Inventory Policy":
					product.variants?.[0]?.inventory_policy ?? "deny", //deny or continue
				"variant fulfillment service":
					product.variants?.[0]?.fulfillment_service ?? "manual",
				"Variant Weight": product.variants?.[0]?.grams?.toString() ?? "",
				"Variant Weight Unit": "g",
				Description: product.body_html,
				Vendor: product.vendor,
				//inventory traking
				//out of stock purchase
				"Template Suffix": product.template_suffix,
				//published
				// Status: product.status,
			},
		};

		// metadata
		if (product.metadata?.length > 0) {
			for (const meta of product.metadata) {
				if (meta.value === "undefined" || meta.key.startsWith("product_type_"))
					continue;
				rowData[`meta_${meta.key}`] = meta.value;
			}
		}
		rowData = {
			...rowData,
			...{
				// quantity: product.available?.toString() ?? "",
				// "Ppblication Exceptions": product.publicationExceptions?.join(", ") ?? "",
				// "publications Type": product.publicationType || "",
				// publication: product.publications?.join(", ") ?? "",

				// "Created at": product.created_at,
				"external id": product.external_id,
				id: product.id,
				// "Updated at": product.updated_at,
				// Url: product.url,
				variant_id: product.variants?.[0]?.id?.toString() ?? "",
				"variant grams": product.variants?.[0]?.grams?.toString() ?? "",
				// "Variant Inventory Quantity":
				// 	product.variants?.[0]?.inventory_quantity?.toString() ?? "",
				"variant position": product.variants?.[0]?.position?.toString() ?? "",
				variant_image_id: product.variants?.[0]?.image_id?.toString() ?? "",
				variant_external_id:
					product.variants?.[0]?.external_id?.toString() ?? "",
				variant_inventory_item_id:
					product.variants?.[0]?.inventory_item_id?.toString() ?? "",

				//unused option keys
				option_external_id: product.options?.[0]?.external_id?.toString() ?? "",
				//unused variant keys
				productSync: product.productSync ?? "",
				"variant __typename": "product_variant",
				// 	"variant created_at": product.variants?.[0]?.created_at,
				// 	"variant inventory_item_id": product.variants?.[0]?.inventory_item_id?.toString() ?? "",
				// 	"variant inventory_management": "",
				// 	"variant inventory_policy"	: "",
				// 	"variant inventory_quantity": "",
				"variant option2": "",
				"variant option3": "",
				// "variant product": "",
				// 	"variant product_external_id": "",
				// 	"variant requires_shipping": "",
				"variant taxable": "true",
				//	"variant title"
				// 	"variant updated_at": "",
				// 	"variant user": "",
				// 	"variant user_id": "",
			},
		};

		return rowData;
	} catch (e) {
		return {} as RowData;
	}
}

export async function searchProductsRow(
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	query: any = {},
	limit = 100,
	page = 1,
	heavy = false,
): Promise<{ data: RowData[]; pagination: IPagination }> {
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	const response = await axios.post(
		`${WORKERS.products}/${siteId}/search?heavy=${heavy}`,
		query,
		{
			params: { limit, page },
			headers: {
				Authorization: bearerToken,
			},
		},
	);
	const data = response.data.data.map((product: IProduct) =>
		convertIProductToRowData(product),
	);
	const pagination = response.data.pagination;
	return { data, pagination };
}

export async function getProductById(id: string): Promise<RowData> {
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	const response = await axios.get(
		`${WORKERS.products}/${siteId}/product?id=${id}`,
		{
			headers: {
				Authorization: bearerToken,
			},
		},
	);

	return convertIProductToRowData(response.data.data);
}

export async function searchProductsByBarcode(ean: string, heavy = false) {
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	const response = await axios.post(
		`${WORKERS.products}/${siteId}/search?heavy=${heavy}&limit=100&page=1`,
		{ variants: { barcode: { _eq: ean } } }, //{ variants: { barcode: { _in: eans } } },
		{
			headers: {
				Authorization: bearerToken,
			},
		},
	);

	let result: resultHeavy[] = response.data.data;
	result = result.reduce((acc: resultHeavy[], current) => {
		const x = acc.find((item) => item.handle === current.handle);
		if (!x) {
			return acc.concat([current]);
		}
		return acc;
	}, []);
	return result;
}

export async function getProductsInQueue() {
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	if (!bearerToken) {
		throw new Error("Bearer token non fourni");
	}
	const response = await axios.get(
		`${WORKERS.trigger}?taskId=products/create&siteId=${siteId}?triggerSecretKey=${process.env.REACT_APP_TRIGGER_SECRET_KEY}`,
	);
	return response.data;
}

export async function deleteProductsInQueue(runId: string) {
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	if (!bearerToken) {
		throw new Error("Bearer token non fourni");
	}
	const response = await axios.delete(
		`${WORKERS.trigger}?taskId=products/create&siteId=${siteId}&runId=${runId}?triggerSecretKey=${process.env.REACT_APP_TRIGGER_SECRET_KEY}`,
	);

	return response.data;
}

// const maxSize = 150000;

// const calculateChunkSize = (chunk: Product[]) => {
// 	return new Blob([JSON.stringify(chunk)]).size;
// };

export async function createProductWithQueue(
	products: RowData[],
	userId: string,
	productChannels: string[],
) {
	const payload = convertRowDataToProduct(products, false, productChannels);
	const data = [];
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	if (!bearerToken) {
		throw new Error("Bearer token non fourni");
	}
	if (!siteId) {
		throw new Error("Vous n'êtes plus connecté");
	}
	for (let i = 0; i < payload.length; i += 20) {
		await new Promise((resolve) => setTimeout(resolve, 500));
		const productChunk = payload.slice(i, i + 20);

		const payloads = productChunk.map((product) => ({
			siteId,
			productDto: product,
			userId,
		}));

		try {
			const response = await axios.post(`${WORKERS.trigger}`, {
				siteId: siteId,
				taskId: "products/create",
				payload: payloads,
			});
			data.push(response);
		} catch (error) {
			console.error(`Erreur lors de la création des produits : ${error}`);
			throw new Error(`Erreur lors de la création des produits : ${error}`);
		}
	}

	return data;
}

export async function updateProductWithQueue(
	products: RowData[],
	userId: string,
) {
	const product = convertRowDataToProduct(products, true);
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	const data = [];

	if (!bearerToken) throw new Error("Bearer token non fourni");
	if (!siteId) throw new Error("Vous n'êtes plus connecté");
	for (let i = 0; i < product.length; i += 20) {
		await new Promise((resolve) => setTimeout(resolve, 500));
		const productChunk = product.slice(i, i + 20);

		for (const product of productChunk) {
			if (!product.id || !product.external_id) {
				throw new Error(
					`Le produit ${product.variants[0].barcode} n'a pas d'ID`,
				);
			}
		}
		const payloads = productChunk.map((product) => ({
			productDto: product,
			siteId,
			userId,
			multisite: true,
			external_id: product.external_id,
		}));

		try {
			const response = await axios.put(`${WORKERS.trigger}?triggerSecretKey=${process.env.REACT_APP_TRIGGER_SECRET_KEY}`, {
				siteId,
				taskId: "products/update",
				payload: payloads,
			});
			data.push(response);
		} catch (error) {
			console.error(`Erreur lors de la mise à jour des produits : ${error}`);
			throw new Error(`Erreur lors de la mise à jour des produits : ${error}`);
		}
	}
	return data;
}

export async function updateSingleProductWithQueue(
	product: RowData,
	userId: string,
) {
	const payload = convertRowDataToProduct([product], true);
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	if (!bearerToken) throw new Error("Bearer token non fourni");

	try {
		const response = await axios.post(`${WORKERS.trigger}?triggerSecretKey=${process.env.REACT_APP_TRIGGER_SECRET_KEY}`, {
			taskId: "products/update",
			payload: {
				siteId,
				productDto: payload[0],
				userId,
			},
		});
		return response.data;
	} catch (error) {
		console.error(`Erreur lors de la mise à jour du produit : ${error}`);
		throw new Error(`Erreur lors de la mise à jour du produit : ${error}`);
	}
}

export async function getProductsChannels() {
	const siteId = localStorage.getItem("siteId");
	const bearerToken = queryParameters.get("act");
	const response = await axios.get(
		`${WORKERS.products}/${siteId}/publications`,
		{
			headers: {
				Authorization: bearerToken,
			},
		},
	);

	return response.data.data;
}

export async function getAllProductsChannelsTypesLinks() {
	const siteId = localStorage.getItem("siteId");
	const bearerToken = queryParameters.get("act");
	const response = await axios.get(
		`${WORKERS.products}/${siteId}/publication-type-links`,
		{
			headers: {
				Authorization: bearerToken,
			},
		},
	);

	return response.data.data;
}
