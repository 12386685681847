import { Modal, Radio, RadioGroup, Button, Flex } from "@mantine/core";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { ShowSheetSelectionProps } from "src/@types/props";


export function ShowSheetSelection({
	opened,
	actions,
	fileName,
	onSheetSelect,
}: ShowSheetSelectionProps) {
	const { t } = useTranslation();
	const [selectedSheet, setSelectedSheet] = useState<string>(fileName[0] || "");

	useEffect(() => {
		if (fileName.length > 0) {
			setSelectedSheet(fileName[0]);
		}
	}, [fileName]);

	const handleSheetSelect = () => {
		onSheetSelect(selectedSheet);
		actions.close();
	};

	return (
		<>
			<Modal
				opened={opened}
				onClose={actions.close}
				title={t("showSheetSelection.title")}
			>
				<RadioGroup value={selectedSheet} onChange={setSelectedSheet}>
					<Flex direction="column" gap={10}>
						{fileName.map((file) => (
							<Radio key={file} value={file} label={file} />
						))}
					</Flex>
				</RadioGroup>
				<Flex justify="end" mt="md">
					<Button onClick={handleSheetSelect} disabled={!selectedSheet}>
						{t("showSheetSelection.confirm")}
					</Button>
				</Flex>
			</Modal>
		</>
	);
}
