import { Text, Loader, Flex, Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { deleteProductsInQueue } from "src/redux/slices/product";
import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import type { InQueuePopupProps } from "src/@types/props";


export default function InQueuePopup({
	productsInQueue,
	awaitingProduct,
}: InQueuePopupProps) {
	const { t } = useTranslation();
	const [inQueueModal, inQueueModalActions] = useDisclosure(false);

	const handleDeleteProductInQueue = async (runId: string) => {
		try {
			const result = await deleteProductsInQueue(runId);

			if (result.ok) {
				notifications.show({
					title: t("inQueuePopup.notification.success.title"),
					message: `${result.data}`,
					color: "blue",
				});
			} else {
				notifications.show({
					title: t("inQueuePopup.notification.error.title"),
					message: t("inQueuePopup.notification.error.message"),
					color: "red",
				});
			}
		} catch (error) {
			notifications.show({
				title: t("inQueuePopup.notification.catchError.title"),
				message: `${error}`,
				color: "red",
			});
		}
	};
	const handleDeleteAllProductsInQueue = async () => {
		for (const product of productsInQueue.inProgress) {
			await handleDeleteProductInQueue(product.id);
			await new Promise((resolve) => setTimeout(resolve, 100));
		}
	};
	return (
		<>
			<div
				style={{
					marginLeft: "10px",
					marginTop: "14px",
					position: "relative",
					zIndex: "10",
					width: "fit-content",
				}}
			>
				<Flex align={"center"} gap={"20px"}>
					<Loader color="blue" type="dots" />
					<Text>
						{awaitingProduct && productsInQueue.inProgress.length < 1 ? (
							<>{t("inQueuePopup.waiting")} </>
						) : (
							<>
								{t("inQueuePopup.inQueue")}{" "}
								<span style={{ display: "inline", fontWeight: "700" }}>
									{productsInQueue.inProgress.length < 1000
										? productsInQueue.inProgress.length
										: "1000+"}
								</span>
							</>
						)}
					</Text>
					<Button
						display={productsInQueue.inProgress.length < 1 ? "none" : "flex"}
						variant="outline"
						onClick={inQueueModalActions.open}
					>
						{t("inQueuePopup.view")}
					</Button>
				</Flex>
			</div>

			<Modal
				opened={inQueueModal}
				onClose={inQueueModalActions.close}
				title={t("inQueuePopup.modal.title")}
			>
				<Flex direction={"column"} gap={10} align="center">
					<Button
						variant="outline"
						color="red"
						onClick={handleDeleteAllProductsInQueue}
					>
						{t("inQueuePopup.deleteAll")}
					</Button>
					{productsInQueue.inProgress.map((item) => {
						const barcode = item.tags.find((tag: string) =>
							tag.includes("barcode_"),
						);
						const barcodeValue = barcode ? barcode.substring(8) : "";
						return (
							<Flex key={item.id} gap={10} justify="space-between" w={"100%"}>
								<Flex gap={10} align={"center"}>
									<Text c={"blue"}>
										{item.taskIdentifier.startsWith("products/create")
											? t("inQueuePopup.create")
											: t("inQueuePopup.update")}
									</Text>
									<Loader color="blue" type="dots" />
									<Text>{barcodeValue}</Text>
								</Flex>
								<Button
									variant="transparent"
									color="red"
									size="small"
									onClick={() => handleDeleteProductInQueue(item.id)}
								>
									{t("inQueuePopup.delete")}
								</Button>
							</Flex>
						);
					})}
				</Flex>
			</Modal>
		</>
	);
}
