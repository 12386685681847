/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Flex,
	Loader,
	Modal,
	Select,
	Switch,
	TextInput,
	Tooltip,
} from "@mantine/core";
import { useDisclosure, useLocalStorage } from "@mantine/hooks";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import ConfirmModal from "./confirmModal";
import { ColumnMappingModal } from "./columnMapping";
import {
	convertIProductToRowData,
	searchProductsByBarcode,
} from "../redux/slices/product";
import { getMetafields } from "../redux/slices/metafields";
import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import type { ColDef, ICellRendererParams } from "ag-grid-community";
import type { PreviewImportModalProps, RowData,Mapping } from "src/@types/props";
interface OurColDef extends ColDef {
	jsonField?: string;
}

const doNotEdit = [
	// "Handle",
	"Variant Barcode",
	"Variant SKU",
	"meta_reference_interne",
	"Variant Reseller Price",
	"Template suffix",
];

const isValueDifferent = (
	jsonValue: string,
	expectedValue: string | undefined,
): boolean => {
	if (jsonValue === "" && expectedValue === undefined) {
		return false;
	}
	return jsonValue !== expectedValue;
};

const getUpdatedColDefs = (
	mappings: { [key: string]: string },
	skipColumnDef: OurColDef,
	baseRowData: RowData[],
	t: (key: string) => string,
): OurColDef[] => {
	const allColumns: string[] = Object.keys(mappings);

	const actionColumnDef: OurColDef = {
		field: "Action",
		headerName: "Action",
		editable: false,
		filter: false,
		width: 103,

		cellStyle: (params) => {
			const alternateBackgroundColor =
				params.rowIndex % 2 === 0 ? "tansparent" : "#0000000A";

			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			const cellStyles: any = {
				backgroundColor: alternateBackgroundColor,
			};
			if (params.value === "Mise à jour" || params.value === "Update") {
				cellStyles.color = "green";
			} else if (params.value === "En attente" || params.value === "Pending") {
				cellStyles.color = "blue";
			}
			return cellStyles;
		},
	};

	const updatedColDefs: OurColDef[] = allColumns.map((column) => ({
		jsonField: column,
		field: mappings[column],
		headerName: t(`knownColumns.${mappings[column]}`),
		editable: !doNotEdit.includes(mappings[column]),
		onCellClicked(event) {
			if (doNotEdit.includes(mappings[column])) {
				if (document.hasFocus()) {
					navigator.clipboard.writeText(event.value);

					notifications.show({
						title: mappings[column],
						message: `${event.value}${t("previewImportModal.notification.message")}`,
						color: "blue",
					});
				}
			}
		},
		cellClass: () => {
			if (doNotEdit.includes(mappings[column])) {
				return "pointer-cursor";
			}
			return "";
		},
		cellStyle: (params) => {
			// Définir la couleur de fond en fonction de l'index de la ligne
			const alternateBackgroundColor =
				params.rowIndex % 2 === 0 ? "tansparent" : "#0000000A";

			// Si l'action n'est pas "Mise à jour", renvoyer la couleur d'arrière-plan alternée
			if (params.data?.Action !== "Mise à jour") {
				return { backgroundColor: alternateBackgroundColor };
			}

			const jsonValue: string = params.value || "";
			const updateIndex: number = params.data?.updateIndex;

			const expectedValue: string | undefined =
				updateIndex !== undefined
					? baseRowData[updateIndex]?.[mappings[column]]
					: undefined;

			if (isValueDifferent(jsonValue, expectedValue)) {
				return { backgroundColor: "#0FBD2C21" };
			}
			// Appliquer la couleur d'arrière-plan alternée en cas de valeur identique
			return { backgroundColor: alternateBackgroundColor };
		},
	}));

	return [skipColumnDef, actionColumnDef, ...updatedColDefs];
};

export default function PreviewImportModal({
	opened,
	jsonData,
	onClose,
	setJsonData,
	setAwaitingProduct,
}: PreviewImportModalProps) {
	const { t } = useTranslation();

	const knownColumnsEN = [
		"Title",
		"Variant Price",
		"Description",
		"Product Type",
		"Tags",
		"Template Suffix",
		"Images Src",
		"Variant Barcode",
		"Variant Inventory Policy",
		"Variant Inventory Quantity",
		"Variant Requires Shipping",
		"Variant SKU",
		"Variant Weight Unit",
		"Variant Weight",
		"Vendor",
	];

	const onClosePreview = () => {
		setRowData([]);
		setImportedColumns([]);
		setColumnMappings({});
		setColDefs([]);
		onClose();
		setMappingSave(undefined);
		setJsonData([]);
	};
	const gridRef = useRef<AgGridReact>(null);

	const [mappingSave, setMappingSave] = useState<Mapping | undefined>(
		undefined,
	);
	const [rowData, setRowData] = useState<RowData[]>([]);
	const [importedColumns, setImportedColumns] = useState<string[]>([]);
	const [expectedColumns, setExpectedColumns] = useState<string[]>([]);
	const [colDefs, setColDefs] = useState<OurColDef[]>([]);
	const [blockImport, setBlockImport] = useState<boolean>(true);
	const [, setColumnMappings] = useState<{
		[key: string]: string;
	}>({});
	const [confirmModalOpened, confirmModalActions] = useDisclosure(false);
	const [mappingModalOpened, mappingModalActions] = useDisclosure(false);
	const [rowHeight, setRowHeight] = useState(42);
	const [isCompactView, setIsCompactView] = useLocalStorage<boolean>({
		key: "compactViewPreview",
		defaultValue: false,
	});
	const [ignoreUpdate, setIgnoreUpdate] = useState<boolean>(false);
	const [ignoreCreate, setIgnoreCreate] = useState<boolean>(false);

	const [importType, setImportType] = useState<string>("all");

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const handleSwitchCompactView = (event: any) => {
		setIsCompactView(event.currentTarget.checked);
		setRowHeight(event.currentTarget.checked ? 32 : 42);
	};

	const baseRowData = useRef<RowData[]>([]);

	const rowSelection = "multiple";
	const paginationPageSizeSelector = [50, 100, 250];
	const paginationPageSize = 100;

	const handleSkipClick = (rowIndex: number) => {
		setRowData((prevRowData) => {
			if (!prevRowData) return prevRowData;
			const updatedRowData = [...prevRowData];
			updatedRowData.splice(rowIndex, 1);
			return updatedRowData;
		});
	};

	const handleClosePreview = () => {
		setAwaitingProduct(true);
		onClose();
	};

	const onStopImport = () => {
		mappingModalActions.close();
		onClosePreview();
	};

	const skipColumnDef: OurColDef = {
		field: "skip",
		headerName: "Skip",
		editable: false,
		filter: false,
		width: 90,

		cellStyle: (params) => {
			const alternateBackgroundColor =
				params.rowIndex % 2 === 0 ? "transparent" : "#0000000A";
			return { backgroundColor: alternateBackgroundColor };
		},

		cellRenderer: (params: ICellRendererParams) => (
			<div
				style={{
					width: "100%",
					height: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
				onDoubleClick={() => handleSkipClick(Number(params.node.id))}
				onKeyDown={(e) =>
					e.key === "Enter" && handleSkipClick(Number(params.node.id))
				}
			>
				{t("previewImportModal.skip")} {">>"}
			</div>
		),
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (opened && jsonData.length) {
			const handleJsonData = async (jsonData: string[][]): Promise<void> => {
				if (jsonData.length > 0) {
					const headerRow: string[] = jsonData[0];
					const autoMappedColumns: { [key: string]: string } = {};
					for (const column of headerRow) {
						autoMappedColumns[column] = knownColumnsEN.includes(column)
							? column
							: column;
					}

					setImportedColumns(headerRow);
					mappingModalActions.open();
				} else {
					alert(t("previewImportModal.alert"));
				}
			};
			const fetchMetafields = async () => {
				try {
					const response = await getMetafields();
					if (response.data) {
						setExpectedColumns(
							response.data.map(
								(metafield: { key: string }) => `meta_${metafield.key}`,
							),
						);
					}
				} catch (error) {
					console.error("Error fetching metafields:", error);
				}
			};
			if (expectedColumns.length === 0) {
				fetchMetafields();
			}
			handleJsonData(jsonData);
		}
	}, [opened, jsonData]);

	const handleColumnMappingConfirm = async (newMappings: {
		[key: string]: string;
	}) => {
		setColumnMappings((prevMappings) => {
			const updatedMappings = { ...prevMappings, ...newMappings };
			const updatedColDefs = getUpdatedColDefs(
				updatedMappings,
				skipColumnDef,
				baseRowData.current,
				t,
			);
			setColDefs(updatedColDefs);
			const variantBarcodeField = updatedColDefs.find(
				(colDef) => colDef.field === "Variant Barcode",
			)?.jsonField;

			if (jsonData && jsonData.length > 1 && variantBarcodeField) {
				const barcodeIndex = jsonData[0].indexOf(variantBarcodeField);
				const barcodeList = jsonData.slice(1).map((row) => row[barcodeIndex]);
				const fetchRowData = async () => {
					setRowData([]);
					setBlockImport(true);
					let updateIndexCounter = 0;

					for (let i = 0; i < barcodeList.length; i += 50) {
						const batch = barcodeList.slice(i, i + 50);
						const responses = await Promise.all(
							batch.map(async (barcode, index) => {
								const response = await searchProductsByBarcode(
									String(barcode),
									true,
								);

								const action =
									response.length > 0
										? t("previewImportModal.action.update")
										: t("previewImportModal.action.create");
								const rowDataObj: RowData = { Action: action };
								const row = jsonData[i + index + 1];

								for (const col of Object.keys(updatedMappings)) {
									const colIndex = jsonData[0].indexOf(col);
									if (colIndex !== -1) {
										const newColumnName = updatedMappings[col];
										rowDataObj[newColumnName] = row[colIndex] || "";
									}
								}

								if (
									action === t("previewImportModal.action.update") &&
									response[0]
								) {
									// biome-ignore lint/suspicious/noExplicitAny: <explanation>
									const convertResponse: any[] = [];

									for (let index = 0; index < response.length; index++) {
										const product = response[index];
										const convertedProduct = convertIProductToRowData(
											// biome-ignore lint/suspicious/noExplicitAny: <explanation>
											product as any,
										);
										convertResponse.push(convertedProduct);
									}

									baseRowData.current.push({
										...Object.fromEntries(
											Object.entries(convertResponse[0]).map(([key, value]) => [
												key,
												String(value),
											]),
										),
										updateIndex: updateIndexCounter.toString(),
									});

									rowDataObj.updateIndex = updateIndexCounter.toString();
									updateIndexCounter++;

									rowDataObj.handle = response[0].handle;
									rowDataObj.external_id = response[0].external_id;
									rowDataObj.id = response[0].id;
									if (response[0].images && response[0].images.length > 0) {
										rowDataObj.images_id = response[0].images[0].external_id;
										rowDataObj.images_src = response[0].images[0].src;
									}
									if (response[0].variants && response[0].variants.length > 0) {
										rowDataObj.variant_external_id = response[0].variants[0]
											.external_id
											? response[0].variants[0].external_id
											: "";
										rowDataObj.variant_id = response[0].variants[0].id
											? response[0].variants[0].id.toString()
											: "";
										rowDataObj.variant_image_id = response[0].variants[0]
											.image_id
											? response[0].variants[0].image_id
											: "";
										rowDataObj.variant_inventory_item_id =
											response[0].variants[0]?.inventory_item_id?.toString() ||
											"";
										rowDataObj.variant_variant_inventory_management =
											response[0].variants[0].inventory_management
												? response[0].variants[0].inventory_management
												: "shopify";
										rowDataObj.variant_sku = response[0].variants[0].sku;
									}
									if (response[0].options && response[0].options.length > 0) {
										rowDataObj.option_id = response[0].options[0].id;
										rowDataObj.option_name = response[0].options[0].name;
										rowDataObj.option_position = response[0].options[0].position
											? response[0].options[0].position.toString()
											: "1";
										rowDataObj.option_external_id =
											response[0].options[0].external_id;
										rowDataObj.option_values = response[0].options[0].values[0];
									}
								}
								if (
									action === t("previewImportModal.action.create") &&
									response[0]?.images
								) {
									rowDataObj.images_src = response[0].images[0].src;
								}
								return rowDataObj;
							}),
						);

						setRowData((previouseRowData: RowData[]) => [
							...previouseRowData,
							...responses,
						]);

						await new Promise((resolve) => setTimeout(resolve, 2000));
					}
					setBlockImport(false);
				};

				fetchRowData();
			}

			return updatedMappings;
		});
	};

	const onFilterTextBoxChanged = useCallback(() => {
		if (gridRef.current) {
			const filterTextBox = document.getElementById(
				"filter-text-box-preview",
			) as HTMLInputElement;
			gridRef.current.api.setQuickFilter(filterTextBox.value);
		}
	}, []);

	const handleSetImportType = (value: string | null) => {
		setImportType(value || "all");
		if (value === "all") {
			setIgnoreCreate(false);
			setIgnoreUpdate(false);
		} else if (value === "creationOnly") {
			setIgnoreUpdate(true);
			setIgnoreCreate(false);
		} else if (value === "updateOnly") {
			setIgnoreUpdate(false);
			setIgnoreCreate(true);
		}
	};

	const itemsTooltip = t("previewImportModal.tooltip.items", {
		returnObjects: true,
	}) as {
		label: string;
		description: string;
	}[];

	return (
		<>
			<ColumnMappingModal
				opened={mappingModalOpened}
				onClose={mappingModalActions.close}
				onStopImport={onStopImport}
				columns={importedColumns}
				onConfirm={(newMappings) => {
					handleColumnMappingConfirm(newMappings);
				}}
				availableName={[...expectedColumns, ...knownColumnsEN]}
				mappingSave={mappingSave}
				setMappingSave={setMappingSave}
			/>
			<Modal
				opened={opened}
				onClose={onClosePreview}
				title={t("previewImportModal.modal.title")}
				size={"100%"}
				closeOnClickOutside={false}
				closeOnEscape={false}
				centered
			>
				<div style={{ height: "80vh" }}>
					<div
						className="ag-theme-quartz"
						style={{ height: "calc(100% - 56px)", width: "100%" }}
					>
						<Flex justify={"space-between"} p={10}>
							<Flex gap={10} align="center">
								<Button
									style={{ marginLeft: "10px" }}
									onClick={() => {
										mappingModalActions.open();
									}}
								>
									{t("previewImportModal.modal.rename")}
								</Button>
								<Button
									disabled={blockImport}
									style={{ marginLeft: "10px" }}
									onClick={confirmModalActions.open}
								>
									{t("previewImportModal.modal.confirm")}
								</Button>
								{blockImport && <Loader color="blue" type="dots" />}

								<ConfirmModal
									opened={confirmModalOpened}
									actions={confirmModalActions}
									rowData={rowData}
									setAwaitingProduct={setAwaitingProduct}
									handleClosePreview={handleClosePreview}
									ignoreUpdate={ignoreUpdate}
									ignoreCreate={ignoreCreate}
								/>
							</Flex>
							<Flex gap={10} align="center">
								<Flex align="center">
									<label style={{ marginRight: "8px" }}>
										{t("previewImportModal.tooltip.title")}
									</label>
									<Tooltip
										multiline
										w={300}
										label={
											<div>
												{itemsTooltip.map((item, index) => (
													// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
													<div key={index} style={{ marginTop: "4px" }}>
														<strong>{item.label}</strong> : {item.description}
													</div>
												))}
											</div>
										}
									>
										<Icon
											width={18}
											height={18}
											icon="solar:question-circle-bold"
										/>
									</Tooltip>
								</Flex>
								<Select
									data={[
										{
											value: "all",
											label: `${t("previewImportModal.importType.all")}`,
										},
										{
											value: "creationOnly",
											label: `${t("previewImportModal.importType.creationOnly")}`,
										},
										{
											value: "updateOnly",
											label: `${t("previewImportModal.importType.updateOnly")}`,
										},
									]}
									defaultValue="all"
									value={importType}
									onChange={(e) => handleSetImportType(e)}
									allowDeselect={false}
									placeholder="Pick value"
									w={150}
								/>
							</Flex>

							<Flex gap={40} align={"center"}>
								<Switch
									labelPosition="left"
									label={t("previewImportModal.modal.compact")}
									checked={isCompactView}
									onChange={handleSwitchCompactView}
								/>
								<Flex gap={10} align={"center"}>
									<span>{t("previewImportModal.modal.quickFilter")}</span>
									<TextInput
										type="text"
										id="filter-text-box-preview"
										placeholder={t("previewImportModal.modal.filter")}
										onInput={onFilterTextBoxChanged}
									/>
								</Flex>
							</Flex>
						</Flex>

						<AgGridReact
							ref={gridRef}
							rowData={rowData}
							columnDefs={colDefs}
							rowSelection={rowSelection}
							rowHeight={rowHeight}
							pagination={true}
							paginationPageSize={paginationPageSize}
							paginationPageSizeSelector={paginationPageSizeSelector}
							animateRows={false}
							suppressFieldDotNotation={true}
							// suppressScrollOnNewData={true}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
}
