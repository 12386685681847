import "./App.css";
import TableData from "./components/tableData";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import type { MantineColorsTuple, MantineThemeOverride } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

const paleBlue: MantineColorsTuple = [
	"#eef3ff",
	"#dce4f5",
	"#b9c7e2",
	"#94a8d0",
	"#748dc1",
	"#5f7cb8",
	"#5474b4",
	"#44639f",
	"#39588f",
	"#2d4b81",
];
const blue: MantineColorsTuple = [
	"#e5f2ff",
	"#cde0ff",
	"#9abeff",
	"#629bff",
	"#367cff",
	"#1869ff",
	"#005fff",
	"#004fe5",
	"#0047cd",
	"#003cb6",
];
const red: MantineColorsTuple = [
	"#ffebe5",
	"#ffd6cd",
	"#ffad9a",
	"#ff8063",
	"#ff5b36",
	"#ff4218",
	"#ff3507",
	"#e42700",
	"#cc1f00",
	"#b21300",
];

const primaryPurple: MantineColorsTuple = [
	"#f2ebff",
	"#d8c7ff",
	"#b696ff",
	"#8e64ff",
	"#6e3ffe",
	"#5d3ffe",
	"#5146F5",
	"#4a3ee0",
	"#4237bd",
	"#3a2fa1",
];

const mantineTheme: MantineThemeOverride = {
	colors: {
		"primary-purple": primaryPurple, // Ajout de la couleur principale
		"pale-blue": paleBlue,
		blue: blue,
		red: red,
	},
	primaryColor: "primary-purple", // Utilisation de la couleur principale
};

function App() {
	const queryParameters = new URLSearchParams(window.location.search);
	const accessToken = queryParameters.get("act");
	const siteId = queryParameters.get("siteId");

	if (!accessToken) {
		return <div>Vous ne pouvez pas accéder à cette page</div>;
	}

	if (!siteId) {
		return <div>Vous ne pouvez pas accéder à cette page</div>;
	}

	return (
		<>
			<MantineProvider theme={mantineTheme} defaultColorScheme="light">
				<Notifications
					style={{
						position: "absolute",
						top: 20,
						right: 20,
						zIndex: 99999,
						overflow: "hidden",
					}}
				/>
				<TableData />
			</MantineProvider>
		</>
	);
}

export default App;
